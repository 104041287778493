import React from "react"
import { Link } from "gatsby"
import Header from "../components/header"
import "../style/style.css"

import Flag from "react-flagpack"

const Index = () => {
  return (
    <div className="App">
      <div className="container homepage">
        <Header title="Past Races" />
        <p>
          These are races that have already{" "}
          <span className="underline">finished</span>:
        </p>
        <Link to="/vuelta-2020/" className="raceLink vuelta">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Vuelta a España 2020
        </Link>
        <Link to="/flanders-2020/" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour of Flanders 2020
        </Link>
        <Link to="/giro-2020/" className="raceLink giro">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Giro D'Italia 2020
        </Link>
        <Link
          to="/panne-2020/"
          className="raceLink"
          style={{ fontSize: `18px` }}
        >
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Driedaagse Brugge - De Panne 2020
        </Link>
        <Link to="/etoile-de-besseges-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Étoile de Bessèges 2021
        </Link>
        <Link to="/kuurne-bruxelles-kuurne-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Kuurne 2021
        </Link>
        <Link to="/strade-bianche-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Strade Bianche 2021
        </Link>
        <Link to="/paris-nice-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Paris Nice 2021
        </Link>
        <Link to="/tirreno-adriatico-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tirreno Adriatico 2021
        </Link>
        <Link to="/nokere-koerse-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Nokere Koerse 2021
        </Link>
        <Link to="/bredene-koksijde-2021" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Bredene Koksijde 2021
        </Link>
        <Link to="/milano-sanremo-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          <span>Milano Sanremo 2021</span>
        </Link>
        <Link to="/brugge-de-panne-2021" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          <span>Brugge - De Panne 2021</span>
        </Link>
        <Link to="/gent-wevelgem-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          <span>Gent Wevelgem 2021</span>
        </Link>
        <Link to="/dwars-door-vlaanderen-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          <span>Dwars door Vlaanderen 2021</span>
        </Link>
        <Link to="/volta-catalunya-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Volta Catalunya 2021
        </Link>
        <Link to="/tour-of-flanders-2021" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour of Flanders 2021
        </Link>
        <Link to="/amstel-gold-race-2021" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Amstel Gold Race 2021
        </Link>
        <Link to="/fleche-wallone-2021" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          La Fleche Wallone 2021
        </Link>
        <Link to="/liege-bastogne-liege-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Liege Bastogne Liege 2021
        </Link>
        <Link to="/giro-2021" className="raceLink giro">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Giro d'Italia 2021
        </Link>
        <Link to="/olympics-2021-tokio-road-race" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Olympics Tokio Road Race 2021 (Men)
        </Link>
        <Link to="/olympics-2021-tokio-road-race-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Olympics Tokio Road Race 2021 (Women)
        </Link>
        <Link to="/tour-de-france-2021" className="raceLink tdf">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour de France 2021
        </Link>
        <Link to="/vuelta-2021" className="raceLink vuelta">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Vuelta a España 2021
        </Link>

        <Link to="/benelux-tour-2021" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Benelux Tour 2021
        </Link>

        <Link to="/world-championships-2021-men" className="raceLink ">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Worlds Road Race (Men) 2021
        </Link>

        <Link to="/world-championships-2021-women" className="raceLink giro">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          World Championship RR (Women) 2021
        </Link>

        <Link to="/paris-roubaix-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Parix Roubaix 2021
        </Link>

        <Link to="/lombardia-2021" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Il Lombardia 2021
        </Link>

        <Link to="/strade-bianche-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Strade Bianche 2022
        </Link>
        <Link to="/strade-bianche-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Strade Bianche 2022 (Women)
        </Link>
        <Link to="/paris-nice-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Paris Nice 2022{" "}
          <Flag size="L" hasBorder={false} className="flagIcon" code={"FRA"} />
        </Link>
        <Link to="/tirreno-adriatico-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tirreno Adriatico 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"ITA"} />
        </Link>
        <Link to="/ronde-van-drenthe-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Ronde van Drenthe 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"NL"} />
        </Link>
        <Link to="/milano-sanremo-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Milano Sanremo 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"ITA"} />
        </Link>
        <Link to="/trofeo-alfredo-binda-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Trofeo Alfredo Binda 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"ITA"} />
        </Link>
        <Link to="/e3-classic-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          E3 Saxo Bank Classic 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>

        <Link to="/volta-catalunya-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Volta Catalunya 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"ES"} />
        </Link>
        <Link to="/gent-wevelgem-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Gent Wevelgem 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>
        <Link to="/gent-wevelgem-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Gent Wevelgem 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>
        <Link to="/dwars-door-vlaanderen-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Dwars Door Vlaanderen 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>
        <Link to="/dwars-door-vlaanderen-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Dwars Door Vlaanderen 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>
        <Link to="/tour-of-flanders-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour of Flanders 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>
        <Link to="/tour-of-flanders-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour of Flanders 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BEL"} />
        </Link>
        <Link to="/itzulia-basque-country-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Itzulia Basque Country 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"ES"} />
        </Link>
        <Link to="/amstel-gold-race-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Amstel Gold Race 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"NL"} />
        </Link>
        <Link to="/amstel-gold-race-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Amstel Gold Race 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"NL"} />
        </Link>
        <Link to="/paris-roubaix-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Paris Roubaix 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"FR"} />
        </Link>
        <Link to="/paris-roubaix-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Paris Roubaix 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"FR"} />
        </Link>
        <Link to="/fleche-wallone-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Fleche Wallone 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BE"} />
        </Link>
        <Link to="/fleche-wallone-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Fleche Wallone 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BE"} />
        </Link>
        <Link to="/liege-bastogne-liege-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Liege Bastogne Liege 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BE"} />
        </Link>
        <Link to="/liege-bastogne-liege-2022-women" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Liege Bastogne Liege 2022 (Women)
          <Flag size="L" hasBorder={false} className="flagIcon" code={"BE"} />
        </Link>
        <Link to="/tour-de-romandie-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour de Romandie 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"CH"} />
        </Link>
        <Link to="/eschborn-frankfurt-2022" className="raceLink">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Eschborn Frankfurt 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"DE"} />
        </Link>
        <Link to="/giro-2022" className="raceLink giro">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Giro d'Italia 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"IT"} />
        </Link>
        <Link to="/tour-de-france-2022" className="raceLink tdf">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour de France 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"FRA"} />
        </Link>
        <Link to="/tour-de-france-femmes-2022" className="raceLink tdf">
          <img alt="bikeIcon" src={require("../style/bike.png")} />
          Tour de France Femmes 2022
          <Flag size="L" hasBorder={false} className="flagIcon" code={"FRA"} />
        </Link>
        <p>
          <Link to="/" className="underline">
            Back to the live races
          </Link>
        </p>
      </div>
    </div>
  )
}

export default Index
